import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'departureAirportRadioButton',
    'departureAirportSection',
    'returnAirportRadioButton',
    'returnAirportSection',
    'roundTripRadioButton',
    'roundTripSection',
    'totalAmount'
  ]

  connect() {
    console.log('formController connected')
    this.handleDepartureAirportSection()
    this.handleReturnAirportSection()
    this.handleRoundTripSection()
    this.calculateTotal()
  }

  toggleDepartureAirportSection() {
    this.toggleSection(event.target.value, this.departureAirportSectionTarget)
  }

  toggleRoundTripSection() {
    this.toggleSection(event.target.value, this.roundTripSectionTarget)
  }

  toggleReturnAirportSection() {
    this.toggleSection(event.target.value, this.returnAirportSectionTarget)
  }

  handleDepartureAirportSection() {
    this.departureAirportRadioButtonTargets.filter(input => input.checked).forEach((element, index) => {
      this.toggleSection(element.value, this.departureAirportSectionTarget)
    })
  }

  handleRoundTripSection() {
    this.roundTripRadioButtonTargets.filter(input => input.checked).forEach((element, index) => {
      this.toggleSection(element.value, this.roundTripSectionTarget)
    })
  }

  handleReturnAirportSection() {
    this.returnAirportRadioButtonTargets.filter(input => input.checked).forEach((element, index) => {
      this.toggleSection(element.value, this.returnAirportSectionTarget)
    })
  }

  calculateTotal() {
    var roundTrip = $('[data-behavior=round-trip]').find('input:radio:checked').val();
    console.log("roundTrip", roundTrip);
    var adults = $('[data-passengers=adults]').val();
    var childrenTwoToEleven = $('[data-passengers=childrenTwoToEleven]').val();
    var childrenUnderTwo = $('[data-passengers=childrenUnderTwo]').val();

    var adultRate = 75;
    var childrenTwoToElevenRate = 40;
    var childrenUnderTwoRate = 0;

    var subTotal = Math.ceil(adults * adultRate) + Math.ceil(childrenTwoToEleven * childrenTwoToElevenRate);

    var total = roundTrip === "true" ? subTotal * 2 : subTotal;

    this.totalAmountTarget.value = total
  }

  toggleSection(display, section) {
    if (display === "true") {
      section.style = ""
    } else {
      section.style = "display:none"
    }
  }
}
