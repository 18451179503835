import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('bookings--times controller connected')
  }

  update() {
    console.log('update order times')
  }

  displayTimes() {
    const stMaartenTimes = [
      "9:30 AM",
      "11:30 AM",
      "12:45 PM",
      "1:45 PM",
      "2:45 PM",
      "3:45 PM",
      "4:30 PM",
      "5:30 PM"
    ]
    const anguillaTimes = [
      "8:00 AM",
      "9:15 AM",
      "10:30 AM",
      "11:30 AM",
      "12:30 PM",
      "1:30 PM",
      "3:00 PM",
      "4:30 PM"
    ]
  }
}
