import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'mainAirportRadioButton',
    'mainAirportSection',
    'returnAirportRadioButton',
    'returnAirportSection',
    'roundTripRadioButton',
    'roundTripSection',
  ]

  connect() {
    if (this.data.get('ferry') === 'true') {
      this.handleRoundTripSection()
      this.handleMainAirportSection()
      this.handleReturnAirportSection()
    }
  }

  // Handle which sections are visible when controller loads
  handleMainAirportSection() {
    this.handleSectionDisplay(this.mainAirportRadioButtonTargets, this.mainAirportSectionTarget)
  }

  handleReturnAirportSection() {
    this.handleSectionDisplay(this.returnAirportRadioButtonTargets, this.returnAirportSectionTarget)
  }

  handleRoundTripSection() {
    this.handleSectionDisplay(this.roundTripRadioButtonTargets, this.roundTripSectionTarget)
  }

  // Toggle section visibility when radio buttons changed
  toggleMainAirportSection() {
    this.toggleSection(event.target.value, this.mainAirportSectionTarget)
  }

  toggleReturnAirportSection() {
    this.toggleSection(event.target.value, this.returnAirportSectionTarget)
  }

  toggleRoundTripSection() {
    this.toggleSection(event.target.value, this.roundTripSectionTarget)
  }

  // Display a section if 'display' is 'true'
  toggleSection(display, section) {
    if (display === 'true') {
      section.style = ''
    } else {
      section.style = 'display:none'
    }
  }

  // Show a section based on the selected radio button. Hide section if none selected
  handleSectionDisplay(radioButtons, section) {
    const checkedButtons = radioButtons.filter(input => input.checked)

    if (checkedButtons.length === 0) {
      this.toggleSection(false, section)
    } else {
      checkedButtons.forEach((element, index) => {
        this.toggleSection(element.value, section)
      })
    }
  }
}
