import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'customCost',
    'cost',
    'roundTripRadioButton',
    'quantity'
  ]

  connect() {
    console.log('bookings--price-calculator connected')
    console.log('base-price: ', this.basePrice)
    console.log('base-passengers: ', this.basePassengers)
    this.toggleCustom()
    this.calculate()
  }

  toggleCustom() {
    console.log('toggleCustom')
    console.log('this.customizable:', this.customizable)
    if (this.customizable && this.customCostTarget.checked) {
      this.costTarget.disabled = false
      this.data.set('custom-price', 'true')
    } else {
      this.costTarget.disabled = true
      this.data.set('custom-price', 'false')
    }
  }

  calculate() {
    console.log('calculate')

    if (!this.customizable || !this.customCostTarget.checked) {
      let amount = 0

      if (this.basePrice > 0) {
        amount = this.basePrice
      }

      this.quantityTargets.forEach((element, index) => {
        if (element.getAttribute('data-description') === 'passenger') {
          if (element.value > this.basePassengers) {
            let extraPassengers = element.value - this.basePassengers

            amount = amount + extraPassengers * parseInt(element.getAttribute('data-price'))
          }
        } else {
          amount = amount + element.value * parseInt(element.getAttribute('data-price'))
        }
      })

      if (this.roundTripSelected) {
        amount = amount * 2
      }

      console.log('total:', amount)

      this.costTarget.value = amount.toFixed(2)
    }
  }

  get roundTripSelected() {
    const checkedButton = this.roundTripRadioButtonTargets.find(input => input.checked)

    if (checkedButton && checkedButton.value === 'true') {
      return true
    } else {
      return false
    }
  }

  get customizable() {
    if (this.data.get('customizable') === 'true') {
      return true
    } else {
      return false
    }
  }

  get basePrice() {
    return parseInt(this.data.get('basePrice'))
  }

  get basePassengers() {
    return parseInt(this.data.get('basePassengers'))
  }
}
