import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "container" ]

  onRemoveSuccess() {
    let [ data, status, xhr ] = event.detail

    this.containerTargets.forEach((container, index) => {
      container.innerHTML = xhr.response
    })

    toastr.success('Promo code removed')
  }

  onRemoveFailure() {
    let [ data, status, xhr ] = event.detail
    toastr.error('Error removing promo code')
  }
}
