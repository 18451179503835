import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'roundTripRadioButton', 'roundTripSection' ]

  connect() {
    console.log('orders select product controller connected')
    this.handleRoundTripSection()
  }

  updateTimes() {

  }



















  toggleRoundTripSection() {
    this.toggleSection(event.target.value, this.roundTripSectionTarget)
  }

  handleRoundTripSection() {
    this.roundTripRadioButtonTargets.filter(input => input.checked).forEach((element, index) => {
      this.toggleSection(element.value, this.roundTripSectionTarget)
    })
  }

  toggleSection(display, section) {
    if (display === "true") {
      section.style = ""
    } else {
      section.style = "display:none"
    }
  }
}
