import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "adult", "child_3_to_11", "child_under_3" ]

  connect() {
    console.log('Round trip passengers connected')
  }

  sync(event) {
    if (this.data.get('round-trip') === 'true') {
      let person_type = event.target.getAttribute('data-person-type')

      if (person_type === 'adult') {
        this.adultTarget.value = event.target.value
      } else if (person_type === 'child_3_to_11') {
        this.child_3_to_11Target.value = event.target.value
      } else if (person_type === 'child_under_3') {
        this.child_under_3Target.value = event.target.value
      }
    }
  }
}
