import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'status', 'redirectField' ]

  connect() {
  }

  updateOrderRedirectField() {
    const creditCardFields = this.statusTargets.filter(input => input.value === 'credit_card')

    if (creditCardFields.length > 0) {
      this.redirectFieldTarget.value = 'true'
    } else {
      this.redirectFieldTarget.value = 'false'
    }
  }
}
