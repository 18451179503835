import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'applyCodeButton',
    'amount',
    'promoCode',
    'promoCodeSection',
    'referenceNumber',
    'referenceNumberSection',
    'status'
  ]

  connect() {
    console.log('payments--form-controller connected')
    this.displayFieldsBasedOnPaymentType('blank')
  }

  handlePaymentType() {
    console.log('handlePaymentType')
    console.log('event.target.value', event.target.value)
    const paymentType = event.target.value

    this.displayFieldsBasedOnPaymentType(paymentType)
  }

  updateSelectedPromoCode() {
    const promoCodeId = event.target.value
    const promoCodeText = event.target.options[event.target.selectedIndex].text

    this.applyCodeButtonTarget.dataset.params = `promo_code_id=${promoCodeId}`

    this.referenceNumberTarget.value = promoCodeText
  }

  onCalculateSuccess() {
    let [ data, status, xhr ] = event.detail
    this.amountTarget.value = (JSON.parse(xhr.response).discount_cents / 100).toFixed(2)
    this.toggleSection('true', this.referenceNumberSectionTarget)
    this.toggleSection('false', this.promoCodeSectionTarget)
  }

  onCalculateFailure() {
    let [ data, status, xhr ] = event.detail
    toastr.error('Promo code not found or expired')
  }

  displayFieldsBasedOnPaymentType(paymentType) {
    if (paymentType === 'credit_card') {
      console.log('credit card')
      this.setStatus('pending')
      this.toggleSection('true', this.referenceNumberSectionTarget)
      this.toggleSection('false', this.promoCodeSectionTarget)
    } else if (paymentType === 'promo_code') {
      console.log('credit card')
      this.setStatus('completed')
      this.toggleSection('false', this.referenceNumberSectionTarget)
      this.toggleSection('true', this.promoCodeSectionTarget)
    } else {
      console.log('other payment type')
      this.setStatus('completed')
      this.toggleSection('true', this.referenceNumberSectionTarget)
      this.toggleSection('false', this.promoCodeSectionTarget)
    }
  }

  setStatus(status) {
    console.log('setting status...')
    this.statusTarget.value = status
  }

  toggleSection(display, section) {
    if (display === 'true') {
      section.style = ''
    } else {
      section.style = 'display:none'
    }
  }
}
