import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'links', 'template' ]

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields'
    console.log(this.wrapperClass)
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    $("[data-ui-behavior=dropdown]").dropdown();
    $("[data-ui-behavior=checkbox]").checkbox()
  }

  remove_association(event) {
    console.log("remove_association")
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector('input[name*="_destroy"]').value = 1
      wrapper.style.display = 'none'
    }
  }

}
