import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    console.log("apply promo code controller connected")
  }

  toggleForm() {
    this.formTarget.classList.toggle('add-promo-code-form--show')
  }
}
