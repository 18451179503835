import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'bookingFieldsContainer' ]

  connect() {
    console.log('bookings--load-fields controller connected')
    this.init();
  }

  update() {
    // console.log('load fields')
    // console.log(event.target)
    // console.log(event.target.value)
    // console.log(this.element)
    const url = this.data.get('url') + '/products/' + event.target.value + '/load_fields/new'

    fetch(url)
      .then(response => response.text())
      .then(html => {
        console.log('this:', this)
        console.log('this.element:', this.element)
        this.element.innerHTML = html.replace(/NEW_RECORD/g, new Date().getTime())
      })
      .then(() => {
        this.init()
      })

  }

  init() {
    $("[data-ui-behavior=dropdown]").dropdown();
    DP.initializeDatepicker();
    DP.initializeTimepicker();
  }
}
